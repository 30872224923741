import * as React from 'react';
import { NavLink } from "react-router-dom";

export function AppHeader(): JSX.Element {
    return (
        <header className="flex justify-around primary-light-bottom-border-strong dark-grey-background mb-1">
            <AppTitle />
        </header>
    );
}

function AppTitle(): JSX.Element {
    return (
        <NavLink to="/" className="link-text">
            <h2 className="ma-1 ml-2 white-text">GrassRoots Care</h2>
        </NavLink>
    );
}
