import * as React from 'react';
import * as ReactDOM from 'react-dom/client';

import App  from './App';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <BrowserRouter basename={""}>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);
