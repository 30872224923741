import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import * as Page from './sections';
import * as Error from './errors';

type AppRoute = {
    id: string,
    path: string,
    area: string,
    element: JSX.Element
}

const AppRoutes: AppRoute[] = [
    { id: "Home", path: "/", area: "", element: <Page.Home /> }
];

export function AppRouter(): JSX.Element {
    return (
        <Routes>
            {AppRoutes.map((route: AppRoute, index:  number) => (
                <Route key={index} path={route.path} element={route.element} />
            ))}
            <Route path="*" element={<Error.NotFound />} />
        </Routes>
    );
}
