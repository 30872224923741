import * as React from 'react';
import './style';
import { AppHeader } from './AppHeader';
import { AppRouter } from './AppRouter';

export default function App() {
    return (
        <div className="flex-vertical flex-fill dark-grey-background">
            <AppHeader />
            <AppRouter />
        </div>
    );
}
