import * as React from 'react';

export function Home(): JSX.Element {
    return (
        <div className="flex-vertical ml-1 mr-1 gap-1">
            <div className='flex justify-center'>
                <div className="basic-box">
                    <h3>Building in Process</h3>
                </div>
            </div>
        </div>
    );
}
